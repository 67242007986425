import React from 'react';

const SparklesIcon = ({ className, isHighlighted }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M5.19922 3V7M3.19922 5H7.19922M6.19922 17V21M4.19922 19H8.19922M13.1992 3L15.4849 9.85714L21.1992 12L15.4849 14.1429L13.1992 21L10.9135 14.1429L5.19922 12L10.9135 9.85714L13.1992 3Z"
        stroke={isHighlighted ? '#6A67FF' : '#191A25'}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SparklesIcon;
