import React from 'react';

const FestivalInfoSkeleton = () => {
  return (
    <div className="w-full h-full">
      <div className="animate-pulse skeleton group w-full bg-gray-200 h-[246px] md:h-[400px]" />
      <div className="animate-pulse skeleton group py-[18px] px-4 sm:px-6 flex-col justify-start items-start gap-4 inline-flex">
        <div className="w-[300px] h-6 md:w-[644px] md:h-[57px] bg-gray-200 rounded-[20px]" />
        <div className="w-[200px] h-6 md:w-[644px] md:h-[57px] bg-gray-200 rounded-[20px]" />
        <div className="w-[200px] h-6 md:w-[644px] md:h-[57px] bg-gray-200 rounded-[20px]" />
        <div className="w-[200px] h-6 md:w-[644px] md:h-[57px] bg-gray-200 rounded-[20px]" />
      </div>
    </div>
  );
};

export default FestivalInfoSkeleton;
