import React, { useEffect, useState } from 'react';

export default function useFetchFestival({ festivalId }) {
  const [isLoading, setIsLoading] = useState(true);
  const [festivalData, setFestivalData] = useState(null);
  const [associatedCommunities, setAssociatedCommunities] = useState([])

  useEffect(() => {
    async function fetchFestivalData() {
      try {
        setIsLoading(true);
        const baseURL = '.netlify/functions/es-festival';

        const url = new URL(baseURL, window.location.origin);
        url.searchParams.append('festivalId', festivalId);

        const response = await fetch(url.href);

        const data = await response.json();
        setFestivalData(data?.festivalData?.properties);
        setIsLoading(false);
      } catch (error) {
        console.log('Error in fetchFestivalData: ', error.message);
        setIsLoading(false);
      }
    }

    fetchFestivalData();
  }, [festivalId]);


  useEffect(() => {
    async function fetchFestivalCommunities() {
      try {
        const url = `/.netlify/functions/es-festival-communities/?festivalId=${festivalId}`;
        const res = await fetch(url);
        const data = await res.json();
        setAssociatedCommunities(data || []);
      } catch (error) {
        console.log('Error in fetchPoiCommunities: ', error.message);
      }
    }

    if (festivalId) fetchFestivalCommunities();
  }, [festivalId]);

  return { associatedCommunities, festivalData, isLoading };
}
