import React from 'react';
import SpotCard from '../molecules/SpotCard';
import SpotCardSkeleton from '../molecules/SpotCardSkeleton';

const scrollClass = 'space-x-4 flex flex-nowrap overflow-x-auto scrollbar-hide';
const gridClass =
  '-mx-px sm:mx-0 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-4';

const SpotCardList = ({
  isVisible = true,
  spots,
  isLoading,
  handleSpotClick,
  isGridView,
}) => {
  const containerClass = isGridView ? gridClass : scrollClass;
  if (!isVisible) return null;
  return (
    <div className={containerClass}>
      {!!spots?.length &&
        spots.map((spot) => (
          <SpotCard
            spot={spot}
            key={spot.db_ref}
            isGridView={isGridView}
            handleSpotClick={handleSpotClick}
          />
        ))}
      {isLoading &&
        new Array(8).map((_, index) => <SpotCardSkeleton key={index} />)}
    </div>
  );
};

export default SpotCardList;
