import React from 'react';

const BuildingIcon = ({ className, isHighlighted }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M25.3333 28V6.66667C25.3333 5.19391 24.1394 4 22.6667 4H9.33333C7.86057 4 6.66667 5.19391 6.66667 6.66667V28M25.3333 28L28 28M25.3333 28H18.6667M6.66667 28L4 28M6.66667 28H13.3333M12 9.3333H13.3333M12 14.6666H13.3333M18.6667 9.3333H20M18.6667 14.6666H20M13.3333 28V21.3333C13.3333 20.5969 13.9303 20 14.6667 20H17.3333C18.0697 20 18.6667 20.5969 18.6667 21.3333V28M13.3333 28H18.6667"
        stroke={isHighlighted ? '#6A67FF' : '#191A25'}
        strokeWidth="2.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BuildingIcon;
