import React from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import FestivalDetail from '../../components/organisms/FestivalDetail';
import ShopHopSponsorImage from '../../images/festival/shop-hop-sponsors.jpg';
import ShopHopRedMapImage from '../../images/festival/shop-hop-red-map.png';
import ShopHopGreenMapImage from '../../images/festival/shop-hop-green-map.png';

const parkings = {
  red: [
    {
      id: 1,
      title: 'Wellspring Church',
      placeId: 'ChIJMautRZ-tGYgRXSK9MZrLDeQ',
      url: 'https://www.google.com/maps/place/Wellspring+Church+of+Grand+Rapids/@42.9558875,-85.6489365,17z/data=!3m1!4b1!4m6!3m5!1s0x8819ad9f45adab31:0xe40dcb9a31bd225d!8m2!3d42.9558875!4d-85.6489365!16s%2Fg%2F1td20wh8?entry=ttu',
    },
    {
      id: 2,
      title: 'Lafontsee Galleries',
      placeId: 'ChIJPeMj_qOtGYgRKHfZyoWfXWg',
      url: 'https://www.google.com/maps/place/Lafontsee+Galleries/@42.961025,-85.648247,17z/data=!3m1!4b1!4m6!3m5!1s0x8819ada3fe23e33d:0x685d9f85cad97728!8m2!3d42.961025!4d-85.648247!16s%2Fg%2F1hc2dp3m8?entry=ttu',
    },
  ],
  green: [
    {
      id: 1,
      title: 'Trinity United Methodist Church',
      placeId: 'ChIJCwzU-AqtGYgRH9Udzq31pjg',
      url: 'https://www.google.com/maps/place/Trinity+United+Methodist+Church/@42.9584356,-85.6413476,17z/data=!3m1!4b1!4m6!3m5!1s0x8819ad0af8d40c0b:0x38a6f5adce1dd51f!8m2!3d42.9584356!4d-85.6413476!16s%2Fg%2F1thsr_dt?entry=ttu',
    },
    {
      id: 2,
      title: 'Fulton Street Market',
      placeId: 'ChIJfX8IEAmtGYgREPwT3mG05kI',
      url: 'https://www.google.com/maps/place/Fulton+Street+Market/@42.9636421,-85.6404418,17z/data=!3m1!4b1!4m6!3m5!1s0x8819ad0910087f7d:0x42e6b461de13fc10!8m2!3d42.9636421!4d-85.6404418!16s%2Fm%2F0127fjbc?entry=ttu',
    },
    {
      id: 3,
      title: 'Rebel',
      placeId: 'ChIJNRUWDQ2tGYgRLe-phpeIqWM',
      url: 'https://www.google.com/maps/place/Rebel/@42.9556483,-85.6304689,17z/data=!3m1!4b1!4m6!3m5!1s0x8819ad0d0d161535:0x63a9889786a9ef2d!8m2!3d42.9556483!4d-85.6304689!16s%2Fg%2F11bx8h1wf4?entry=ttu',
    },
    {
      id: 4,
      title: 'CVS',
      placeId: 'ChIJk8SF3mytGYgRChjHKxYW39c',
      url: 'https://www.google.com/maps/place/CVS/@42.9552546,-85.6314519,17z/data=!3m1!4b1!4m6!3m5!1s0x8819ad6cde85c493:0xd7df16162bc7180a!8m2!3d42.9552546!4d-85.6314519!16s%2Fg%2F1thghn5l?entry=ttu',
    },
  ],
};

const shopHopFestivalData = {
  festivalDbRef: '2326c1b2-088f-48dc-ba41-456f7fb5fb2d',
  staticLocation: 'Uptown Grand Rapids',
  seoTitle: 'Uptown GR Annual Shop Hop 2023 Set For December 7',
  seoDescription:
    'Mark your calendars for the 26th annual Uptown Shop Hop, a festive holiday shopping extravaganza happening on Thursday, December 7th, 2023 from 3pm to 9pm!',
  seoKeywords: [
    'Uptown Shop Hop',
    'Grand Rapids',
    'Holiday shopping',
    'Small businesses',
    'Eastown',
    'East Hills',
    'Wealthy Street',
    'East Fulton',
    'Jolly trolleys',
    'Live entertainment',
    'Festive atmosphere',
  ],
  faqs: [
    {
      id: 1,
      question: "What's the best thing about Switzerland?",
      answer:
        "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
    },
    {
      id: 2,
      question: "What's the best thing about Switzerland?",
      answer:
        "I don't know, but the flag is a big plus. Lorem ipsum dolor sit amet consectetur adipisicing elit. Quas cupiditate laboriosam fugiat.",
    },
  ],
  sponsorImage: ShopHopSponsorImage,
  fallbackHeroImages: {
    desktop: () => <StaticImage src="../../images/festival/shop-hop-desktop-banner.png" alt="Shop Hop Desktop Banner" placeholder="blurred" layout="fullWidth" />,
    mobile: () => <StaticImage src="../../images/festival/shop-hop-mobile-banner.png" alt="Shop Hop Mobile Banner" placeholder="blurred" layout="fullWidth" />,
  },
  mapData: {
    center: [-85.63404239740258, 42.95771175481942],
    zoom: 14,
    maxZoom: 20,
    minZoom: 8,
  },
  freeParkings: Object.values(parkings).flat(),
  mapImageList: [
    {
      id: 1,
      name: 'Red Route Stops',
      color: '#D61313',
      image: ShopHopRedMapImage,
      legendGroups: [
        {
          id: 1,
          title: 'Red Route',
          color: '#D61313',

          legends: [
            { id: 1, legendId: 10, title: 'Hermitage on Diamond' },
            { id: 2, legendId: 11, title: 'Van’s Pastry Shop' },
            { id: 3, legendId: 12, title: 'Cakabakery' },
            { id: 4, legendId: 13, title: 'Art of the Table' },
            { id: 5, legendId: 14, title: 'Basic Bee' },
            { id: 6, legendId: 15, title: 'Speciation' },
            { id: 7, legendId: 16, title: 'Gemini Handmande' },
            { id: 8, legendId: 17, title: 'Hopscotch' },
            { id: 9, legendId: 18, title: 'Lafontsee Galleries' },
          ],
        },
        {
          id: 2,
          title: 'Parking',
          color: '#131BD6',

          legends: parkings.red,
        },
      ],
    },
    {
      id: 2,
      name: 'Green Route Stops',
      color: '#238B28',
      image: ShopHopGreenMapImage,
      legendGroups: [
        {
          id: 1,
          title: 'Green Route',
          color: '#238B28',

          legends: [
            { id: 1, legendId: 1, title: 'Atomic Object' },
            { id: 2, legendId: 2, title: 'Wealthy Theater' },
            { id: 3, legendId: 3, title: 'Brown Butter' },
            { id: 4, legendId: 4, title: 'Rebel' },
            { id: 5, legendId: 5, title: 'Eastown Post Office' },
            { id: 6, legendId: 6, title: 'Fruition' },
            { id: 7, legendId: 7, title: 'Always Betti' },
            { id: 8, legendId: 8, title: 'Closed' },
            { id: 9, legendId: 9, title: 'Blackport Shoppes' },
          ],
        },
        {
          id: 2,
          title: 'Parking',
          color: '#131BD6',

          legends: parkings.green,
        },
      ],
    },
  ],
};

const ShopHopFestival = () => {
  return <FestivalDetail staticFestivalData={shopHopFestivalData} />;
};

export default ShopHopFestival;
