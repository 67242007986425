import { Disclosure } from '@headlessui/react';
import React from 'react';
import MinusIcon from '../atoms/icons/MinusIcon';
import PlusIcon from '../atoms/icons/PlusIcon';

const FAQList = ({ faqs, isVisible }) => {
  if (!isVisible) return null;
  return (
    <dl className="mt-10 space-y-6 divide-y divide-gray-900/10">
      {faqs.map((faq) => (
        <Disclosure key={faq.id} as="div" className="pt-6">
          {({ open }) => (
            <>
              <dt>
                <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                  <span className="text-base font-semibold leading-7">
                    {faq.question}
                  </span>
                  <span className="ml-6 flex h-7 items-center">
                    {open ? (
                      <MinusIcon className="h-6 w-6" aria-hidden="true" />
                    ) : (
                      <PlusIcon className="h-6 w-6" aria-hidden="true" />
                    )}
                  </span>
                </Disclosure.Button>
              </dt>
              <Disclosure.Panel as="dd" className="mt-2 pr-12">
                <p className="text-base leading-7 text-gray-600">
                  {faq.answer}
                </p>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      ))}
    </dl>
  );
};

export default FAQList;
