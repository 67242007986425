import React, { useEffect, useState } from 'react';
import { StaticImage } from 'gatsby-plugin-image';
import { navigate } from 'gatsby';
import useFetchFestival from '../../utils/hooks/useFetchFestival';
import Tabs from '../atoms/tabs';
import ActivityFilter from '../molecules/ActivityFilter';
import ActivityList from './ActivityList';
import FAQList from '../molecules/FAQList';
import FestivalInfo from '../molecules/FestivalInfo';
import { TabIds, tabList } from '../../constants/festival';
import useFetchPoi from '../../utils/hooks/useFetchPoi';
import FestivalInfoSkeleton from '../molecules/FestivalInfoSkeleton';
import BaseLayout from '../templates/BaseLayout';
import SEO from '../atoms/utility/seo';
import FestivalVendorSection from './FestivalVendorSection';
import WalkingMap from '../molecules/WalkingMap';
import useAnalytics from '../../utils/hooks/useAnalytics';
import useMediaQuery from '../../utils/hooks/useMediaQuery';

const defaultOptionForCommunityFilter = {
  db_ref: 'all',
  name: 'All',
};

function FestivalDetailHeroImage({
  isDesktop,
  festivalData,
  heroImages,
  fallbackHeroImages,
}) {
  let imageUrl;
  let altText;
  let fallbackImage;

  if (isDesktop) {
    imageUrl = festivalData?.desktop_hero_image_url;
    altText = 'festival desktop hero';
    fallbackImage = fallbackHeroImages.desktop();
  } else {
    imageUrl = festivalData?.mobile_hero_image_url;
    altText = 'festival mobile hero';
    fallbackImage = fallbackHeroImages.mobile();
  }

  return imageUrl !== 'None' ? (
    <img
      className="w-full h-full object-contain"
      alt={altText}
      src={isDesktop ? heroImages.desktop : heroImages.mobile}
    />
  ) : (
    fallbackImage
  );
}

function FestivalDetail({ staticFestivalData }) {
  const {
    festivalDbRef,
    seoTitle,
    seoDescription,
    seoKeywords,
    sponsorImage,
    fallbackHeroImages,
    faqs,
    staticLocation,
  } = staticFestivalData;

  // Analytics
  const { fireEvent } = useAnalytics();
  const handleTabAnalytics = (previousTabId, currentTabId) => {
    fireEvent('shop_hop_tab_switched', {
      event_category: 'shop_hop',
      event_label: 'tab_switched',
      previous_tab: previousTabId,
      current_tab: currentTabId,
    });
  };

  const { festivalData, isLoading, associatedCommunities } = useFetchFestival({
    festivalId: festivalDbRef,
  });
  const heroImages = {
    desktop:
      festivalData?.desktop_hero_image_url !== 'None'
        ? festivalData?.desktop_hero_image_url
        : fallbackHeroImages.desktop,
    mobile:
      festivalData?.mobile_hero_image_url !== 'None'
        ? festivalData?.mobile_hero_image_url
        : fallbackHeroImages.mobile,
  };

  const [activeTab, setActiveTab] = useState(tabList[0]);
  const [previousTab, setPreviousTab] = useState(tabList[0]);

  const [communityFilterValue, setCommunityFilterValue] = useState(
    defaultOptionForCommunityFilter
  );

  const { poiDetailList: poiList, isLoading: poiLoading } = useFetchPoi({
    poiIds: festivalData?.points_of_interest,
  });

  const isDesktop = useMediaQuery('(min-width: 768px)');

  const handleTabChangeToMap = () => {
    setPreviousTab(activeTab);
    setActiveTab(tabList.find((tab) => tab.id === TabIds.MAP));
    handleTabAnalytics(previousTab.id, activeTab.id);
  };

  const handleSelectCommunityFilter = (community) => {
    if (communityFilterValue.db_ref === community.db_ref) {
      setCommunityFilterValue(defaultOptionForCommunityFilter);
    } else {
      setCommunityFilterValue(community);
      fireEvent('shop_hop_community_filter', {
        event_category: 'shop_hop',
        event_label: 'community_filter',
        community: community.name,
      });
    }
  };

  const handleTabClick = (tab) => {
    setPreviousTab(activeTab);
    setActiveTab(tab);
    handleTabAnalytics(previousTab.id, activeTab.id);
  };

  const handleSponsorImageClick = () => {
    fireEvent('shop_hop_sponsor_image_pressed', {
      event_category: 'shop_hop',
      event_label: 'sponsor_image_pressed',
    });
  };

  const navigateToElfHuntPage = () => {
    navigate('/scavenger/elf-hunt');
  };

  useEffect(() => {
    const hash = window.location.hash?.replace('#', '');
    if (hash) {
      setPreviousTab(activeTab.id);
      setActiveTab(tabList.find((tab) => tab.id === hash) || activeTab);
      handleTabAnalytics(previousTab.id, activeTab.id);
    }
  }, []);

  const [lcpFired, setLcpFired] = useState(false);
  // Function to send LCP data to Google Analytics
  function sendLCPtoGA(value) {
    if (!lcpFired) {
      fireEvent('performance_largest_contentful_paint', {
        event_category: 'performance_metrics',
        event_label: 'Largest Contentful Paint',
        largest_contentful_paint: value,
      });
      setLcpFired(true);
    }
  }

  // Function to listen for LCP using the Performance Observer API
  function trackLCP() {
    if ('PerformanceObserver' in window) {
      const observer = new PerformanceObserver((list) => {
        const entries = list.getEntries();
        const lcpEntry = entries.find(
          (entry) => entry.entryType === 'largest-contentful-paint'
        );
        if (lcpEntry) {
          // The LCP value is in milliseconds
          const lcpValue = lcpEntry.renderTime || lcpEntry.loadTime;
          sendLCPtoGA(lcpValue);
        }
      });
      observer.observe({ type: 'largest-contentful-paint', buffered: true });
    }
  }

  // Call the function to start tracking LCP
  useEffect(() => {
    if (!lcpFired) {
      trackLCP();
    }
  }, []);

  if (isLoading) {
    return <FestivalInfoSkeleton />;
  }

  return (
    <BaseLayout
      mainSectionClass="z-20 relative flex-grow h-full w-full pt-[40px] mb-10"
      hideHeroImage
      customHeaderClass=" "
    >
      <SEO
        title={seoTitle}
        description={seoDescription}
        keywords={seoKeywords}
      />
      <div className="relative mt-8 w-full">
        <FestivalDetailHeroImage
          isDesktop={isDesktop}
          festivalData={festivalData}
          heroImages={heroImages}
          fallbackHeroImages={fallbackHeroImages}
        />
        <div className="bottom-3 right-3 md:bottom-6 md:right-6 absolute items-center gap-1.5 inline-flex">
          <div
            onClick={navigateToElfHuntPage}
            className="cursor-pointer px-3 md:px-4 py-1 bg-dark-grey-gradient rounded-[60px] justify-start items-center gap-1.5 flex"
          >
            <StaticImage
              src="../../images/festival/elf-icon.png"
              alt="elf"
              className="drop-shadow-xl w-[24px] md:w-[42px]"
            />
            <div className="text-white text-xs md:text-lg font-medium font-['DM Sans'] leading-tight">
              Elf Scavenger Hunt
            </div>
          </div>
        </div>
      </div>
      <div className="md:justify-center my-4 md:my-8 flex flex-nowrap overflow-x-auto scrollbar-hide">
        <Tabs
          tabList={tabList}
          activeTab={activeTab}
          handleTabClick={handleTabClick}
        />
      </div>

      <div className="px-8 md:px-32 mb-4 md:mb-8">
        <FestivalInfo
          festivalData={festivalData}
          isVisible={activeTab.id === TabIds.DETAILS}
          associatedCommunities={associatedCommunities}
          handleLocationClick={handleTabChangeToMap}
          location={staticLocation}
        />

        <FAQList faqs={faqs} isVisible={activeTab.id === TabIds.FAQ} />

        <FestivalVendorSection
          isVisible={activeTab.id === TabIds.VENDOR}
          poiList={poiList}
          isLoading={poiLoading}
          mapData={staticFestivalData.mapData}
        />

        {activeTab.id === TabIds.SPONSOR && (
          <div className="w-full h-full">
            <img
              className="w-full h-full object-cover"
              src={sponsorImage}
              onClick={handleSponsorImageClick}
            />
          </div>
        )}
      </div>
      <div className="md:px-32 mb-4 md:mb-8">
        <ActivityFilter
          isVisible={activeTab.id === TabIds.ACTIVITIES}
          hideTimeFilter
          hideCommunityFilter={false}
          communityList={associatedCommunities}
          communityFilterValue={communityFilterValue}
          handleSelectCommunity={handleSelectCommunityFilter}
        />
      </div>
      <div className="px-8 md:px-8">
        <ActivityList
          communityId={communityFilterValue.db_ref}
          festivalId={festivalDbRef}
          isVisible={festivalDbRef && activeTab.id === TabIds.ACTIVITIES}
        />
        {activeTab.id === TabIds.MAP && (
          <WalkingMap
            mapImageList={staticFestivalData.mapImageList}
            mapImageLegends={staticFestivalData.mapImageLegends}
            freeParkings={staticFestivalData.freeParkings}
          />
        )}
      </div>
    </BaseLayout>
  );
}

export default FestivalDetail;
