import React from 'react';

const MapIcon = ({ isHighlighted, className }) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M9.39844 20L3.95122 17.2764C3.61244 17.107 3.39844 16.7607 3.39844 16.382V5.61803C3.39844 4.87465 4.18075 4.39116 4.84565 4.72361L9.39844 7M9.39844 20L15.3984 17M9.39844 20V7M15.3984 17L19.9512 19.2764C20.6161 19.6088 21.3984 19.1253 21.3984 18.382V7.61803C21.3984 7.23926 21.1844 6.893 20.8457 6.72361L15.3984 4M15.3984 17V4M15.3984 4L9.39844 7"
        stroke={isHighlighted ? '#6A67FF' : '#191A25'}
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MapIcon;
