import React, { useState } from 'react';
import SolidMarker from '../atoms/icons/SolidMarker';
import ParkingIcon from '../atoms/icons/ParkingIcon';
import useAnalytics from '../../utils/hooks/useAnalytics';


const WalkingMap = ({ freeParkings, mapImageList }) => {

  const { fireEvent } = useAnalytics();
  
  const [activeImageTab, setActiveImageTab] = useState(mapImageList[0]);

  const handleMapClick = () => {
    fireEvent('shop_hop_jolly_trolley_map_pressed', {
      event_category: 'shop_hop',
      event_label: 'jolly_trolley_map_pressed',
    });
  };

  return (
    <div className="md:mx-8 mb-4 md:mt-12 justify-start items-center">
      <div className="mb-2 text-gray-900 text-base md:text-2xl font-bold font-['DM Sans'] leading-tight">
        Hop on for free from 3-9 PM!
      </div>
      <div className="mb-4 text-gray-900 text-xs md:text-sm font-normal font-['DM Sans'] leading-tight">
        The trolley will come past every 35-45 minutes. Average distance between
        stops is 2-4 blocks.
      </div>
      <div className="mb-4 space-x-2 flex flex-nowrap overflow-x-auto scrollbar-hide">
        <div className="text-center justify-center align-middle cursor-pointer whitespace-nowrap py-1 px-1 text-gray-900 text-xs md:text-sm font-normal font-['DM Sans']">
          Free Parking:
        </div>
        {freeParkings.map((parking) => (
          <a
            target="_blank"
            className="text-center justify-center align-middle cursor-pointer whitespace-nowrap bg-gray-100 py-1 px-2 md:px-4 rounded-md text-default text-xs md:text-sm font-normal font-['DM Sans']"
            href={parking.url}
          >
            {parking.title}
          </a>
        ))}
      </div>
      <div className="justify-between md:justify-start items-center gap-1.5 md:gap-4 flex">
        {mapImageList.map((map) => (
          <div
            key={map.id}
            className={`px-2 md:px-4 py-2 ${
              activeImageTab.id === map.id
                ? 'bg-zinc-100'
                : 'bg-white border border-gray-500 border-opacity-20'
            } rounded-lg mb-[12px] md:mb-[24px] justify-start items-center gap-1.5 flex`}
          >
            <SolidMarker
              className="w-6 h-6 md:w-8 md:h-8 p-[5px] md:[7.5px]"
              strokeColor={map.color}
            />
            <div
              className="cursor-pointer justify-start items-center gap-1 flex"
              onClick={() => setActiveImageTab(map)}
            >
              <div className="text-slate-800 text-xs md:text-xl font-medium font-['DM Sans'] leading-tight">
                {map.name}
              </div>
            </div>
          </div>
        ))}
      </div>

      <div className="w-full h-full">
        <img
          className="w-full h-full object-cover"
          src={activeImageTab.image}
          onClick={handleMapClick}
        />
      </div>
      <div className="mt-4 md:mt-12 -mx-px sm:mx-0 grid grid-cols-2 gap-4">
        {activeImageTab.legendGroups.map((item) => (
          <div key={item.id} className="mt-2">
            <div className="mb-4 md:mb-6 text-gray-900 text-lg md:text-xl font-bold font-['DM Sans'] leading-tight">
              {item.title}
            </div>
            {item.legends.map((legend) => (
              <div
                key={legend.legendId}
                className="mb-[12px] md:mb-[24px] justify-start items-center gap-1.5 flex"
              >
                {legend.legendId ? (
                  <div className="relative w-8 h-8 md:w-10 md:h-10 flex items-center justify-center">
                    <SolidMarker
                      className="absolute transform -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2 min-w-[30px] w-8 h-8 md:w-10 md:h-10 rounded-lg p-[5px] md:[7.5px]"
                      strokeColor={item.color}
                    />
                    <div className="z-0 flex w-8 h-8 md:w-10 md:h-10 justify-center items-center text-white text-xs font-bold font-['DM Sans'] leading-tight">
                      {legend.legendId}
                    </div>
                  </div>
                ) : (
                  <ParkingIcon className="min-w-[30px] w-6 h-6 md:w-8 md:h-8" />
                )}

                <div className="justify-start items-center gap-1 flex">
                  <div className="text-gray-900 text-sm md:text-2xl font-medium font-['DM Sans'] leading-tight">
                    {legend.title}
                  </div>
                </div>
              </div>
            ))}
          </div>
        ))}
      </div>
    </div>
  );
}

export default WalkingMap;
