import React, { useEffect, useState } from 'react';
import SpotCardList from './SpotCardList';
import FestivalMap from './FestivalMap';
import Pagination from '../molecules/Pagination';
import useAnalytics from '../../utils/hooks/useAnalytics';

const PER_PAGE = 10;
const mapElementId = 'vendor-map';

function FestivalVendorSection({ isVisible, poiList, isLoading, mapData }) {
  // Analytics
  const { fireEvent } = useAnalytics();

  const [poiToHighlight, setPoiToHighlight] = useState(null);

  const [currentPage, setCurrentPage] = useState(1);
  const [poiToDisplay, setPoiToDisplay] = useState(poiList.slice(0, PER_PAGE));

  const totalPages = Math.ceil(poiList.length / PER_PAGE);

  const handleSpotClick = (spot) => {
    setPoiToHighlight(spot);
    const element = document.getElementById(mapElementId);
    const offset = window.innerHeight / 2 - element.clientHeight / 2;
    const elementRect = element.getBoundingClientRect();
    const absoluteElementTop = elementRect.top + window.pageYOffset;
    const middle = absoluteElementTop - offset;
    window.scrollTo({ top: middle, behavior: 'smooth' });

    fireEvent('shop_hop_business_card_pressed', {
      event_category: 'shop_hop',
      event_label: 'business_card_pressed',
      name: spot.name,
      id: spot.id,
    });
  };

  const handlePageChange = () => {
    const from = (currentPage - 1) * PER_PAGE;
    const dataToDisplay = poiList.slice(from, from + PER_PAGE);
    setPoiToDisplay(dataToDisplay);
  };

  useEffect(() => {
    handlePageChange();
  }, [poiList, currentPage]);

  if (!isVisible) return null;

  return (
    <div>
      <div className="mt-4 mb-4 md:mb-8 justify-start items-center">
        <div>
          <span className="text-gray-900 text-xl md:text-2xl font-bold font-['DM Sans'] leading-tight">
            Participating Businesses{' '}
          </span>
          <br className="block md:hidden" />
          <span className="text-gray-900 text-sm font-normal font-['DM Sans'] leading-tight">
            (sorted in alphabetical order)
          </span>
        </div>
        <div className="my-2 md:my-4 text-gray-900 text-sm font-normal font-['DM Sans'] leading-tight">
          During the event, Uptown has partnered with Hope Network to provide
          complimentary transport van service to guests who may require mobility
          assistance. Call{' '}
          <a
            href="tel:616-243-0876"
            className="cursor-pointer text-violet-500 underline"
          >
            616-243-0876
          </a>{' '}
          for curbside pickups and dropoffs.
        </div>
      </div>
      <FestivalMap
        isVisible={isVisible}
        poiList={poiList}
        mapData={mapData}
        mapElementId={mapElementId}
        poiToHighlight={poiToHighlight}
      />

      <div className="mt-4 md:mt-12">
        <SpotCardList
          spots={poiToDisplay}
          isLoading={isLoading}
          handleSpotClick={handleSpotClick}
          isVisible={isVisible}
          isGridView
        />
        <Pagination
          isLoading={isLoading}
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={setCurrentPage}
        />
      </div>
    </div>
  );
}

export default FestivalVendorSection;
