import InfoIcon from '../components/atoms/icons/InfoIcon';
import SparklesIcon from '../components/atoms/icons/SparklesIcon';
import QuestionMarkIcon from '../components/atoms/icons/QuestionMarkIcon';
import BuildingIcon from '../components/atoms/icons/BuildingIcon';
import MapIcon from '../components/atoms/icons/MapIcon';
import TrendingIcon from '../components/atoms/icons/TrendingIcon';

export const TabIds = Object.freeze({
  DETAILS: 'details',
  ACTIVITIES: 'activities',
  VENDOR: 'vendor',
  MAP: 'map',
  SPONSOR: 'sponsor',
  FAQ: 'faq',
});

export const tabList = [
  { id: TabIds.DETAILS, name: 'Details', icon: InfoIcon },
  { id: TabIds.ACTIVITIES, name: 'Activities', icon: TrendingIcon },
  { id: TabIds.VENDOR, name: 'Vendor', icon: BuildingIcon },
  { id: TabIds.MAP, name: 'Trolley & Free Parking Map', icon: MapIcon },
  { id: TabIds.SPONSOR, name: 'Sponsor', icon: SparklesIcon },
  // { id: TabIds.FAQ, name: 'FAQ', icon: QuestionMarkIcon },
];
