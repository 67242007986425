import React from 'react';

const SolidMarker = ({ className, strokeColor = '#ffffff' }) => {
  return (
    <svg
      width="18"
      height="21"
      viewBox="0 0 18 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.5306 2.5157C5.90473 -0.838566 11.3753 -0.838566 14.7494 2.5157C18.1235 5.86996 18.1235 11.3083 14.7494 14.6626L8.64 20.736L2.5306 14.6626C-0.843532 11.3083 -0.843532 5.86996 2.5306 2.5157ZM8.64 11.0432C10.0034 11.0432 11.1086 9.94446 11.1086 8.58913C11.1086 7.2338 10.0034 6.13509 8.64 6.13509C7.27664 6.13509 6.17143 7.2338 6.17143 8.58913C6.17143 9.94446 7.27664 11.0432 8.64 11.0432Z"
        fill={strokeColor}
      />
      <rect x="6" y="6" width="6" height="6" fill={strokeColor} />
    </svg>
  );
};

export default SolidMarker;
