import { useEffect, useState } from 'react';

const useAnalytics = () => {
  const [payload, setPayload] = useState({});
  const [gTagAvailable, setGTagAvailable] = useState(false);

  // Analytics Implementation
  const googleAnalytics = (eventName, eventParams) => {
    if (typeof window.gtag !== 'function') {
      setGTagAvailable(false);
      return;
    }

    // google analytics implementation
    window.gtag('event', eventName, eventParams);

    setGTagAvailable(true);
  };

  const fireEvent = (eventName, eventParams = {}) => {
    if (process.env.NODE_ENV === 'development') return;
    setPayload({ eventName, eventParams });
    try {
      googleAnalytics(eventName, eventParams);
    } catch (error) {
      console.error('Error triggering custom event:', error.message);
    }
  };

  // Fallback in case script is not loaded for onMount Events since it is handled by plugin
  useEffect(() => {
    if (gTagAvailable) return;
    setTimeout(() => {
      fireEvent(payload);
    }, 500);
  }, [gTagAvailable]);

  return { fireEvent };
};

export default useAnalytics;
