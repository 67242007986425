import React from 'react';

const ParkingIcon = ({ className, strokeColor = '#141BD7' }) => {
  return (
    <svg
      width="18"
      height="20"
      viewBox="0 0 18 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2.5306 2.42641C5.90473 -0.808802 11.3753 -0.808803 14.7494 2.42641C18.1235 5.66162 18.1235 10.9069 14.7494 14.1421L8.64 20L2.5306 14.1421C-0.843533 10.9069 -0.843533 5.66162 2.5306 2.42641ZM8.64 10.6512C10.0034 10.6512 11.1086 9.59149 11.1086 8.28427C11.1086 6.97705 10.0034 5.91734 8.64 5.91734C7.27665 5.91734 6.17143 6.97705 6.17143 8.28427C6.17143 9.59149 7.27665 10.6512 8.64 10.6512Z"
        fill={strokeColor}
      />
      <rect x="6" y="5.78711" width="6" height="5.78704" fill={strokeColor} />
      <path
        d="M5.92363 14V4.49208H9.44156C10.2022 4.49208 10.8315 4.61885 11.3296 4.87239C11.8276 5.12594 12.1989 5.47456 12.4433 5.91827C12.6878 6.36197 12.8101 6.86 12.8101 7.41237C12.8101 7.93757 12.6924 8.42202 12.4569 8.86572C12.2215 9.30037 11.8548 9.65352 11.3567 9.92518C10.8587 10.1878 10.2203 10.3191 9.44156 10.3191H7.66222V14H5.92363ZM7.66222 8.90647H9.3329C9.93959 8.90647 10.3742 8.77517 10.6368 8.51257C10.9085 8.24092 11.0443 7.87418 11.0443 7.41237C11.0443 6.9415 10.9085 6.57477 10.6368 6.31216C10.3742 6.04051 9.93959 5.90468 9.3329 5.90468H7.66222V8.90647Z"
        fill="white"
      />
    </svg>
  );
};

export default ParkingIcon;
