import React from 'react';

const SpotCardSkeleton = () => {
  return (
    <div className="animate-pulse skeleton w-[100px] md:w-[136px h-[120px] md:h-40 p-3 bg-white rounded-2xl shadow border border-neutral-200 flex-col gap-1.5 justify-center inline-flex">
      <div className="flex-col justify-center items-center gap-2 flex">
        <div className="skeleton-text h-5 rounded-full w-12 h-12 md:w-16 md:h-16 bg-gray-200 mb-2"></div>
        <div className="skeleton-text h-5 rounded-xl w-full bg-gray-200 mb-2"></div>
      </div>
    </div>
  );
};

export default SpotCardSkeleton;
