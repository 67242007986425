import React from 'react';
import TimerIcon from '../atoms/icons/TimerIcon';
import LocationPin from '../atoms/icons/LocationPin';
import { formatActivityDateTime } from '../../utils/helpers/activity';
import LinkIcon from '../atoms/icons/LinkIcon';
import PaperIcon from '../atoms/icons/PaperIcon';
import TagIcon from '../atoms/icons/TagIcon';

const FestivalInfo = ({ associatedCommunities, festivalData, isVisible, location, handleLocationClick }) => {
  const {
    name,
    description,
    start_time_utc: startDateUTC,
    end_time_utc: endDateUTC,
    url: website,
    price,
  } = festivalData;

  const formattedFestivalDateTime = formatActivityDateTime({
    startDate: startDateUTC,
    endDate: endDateUTC,
  });

  if (!isVisible) return null;
  return (
    <div className="pt-2 lg:pt-8 px-2 md:px-32">
      <div className="flex-col justify-start items-start inline-flex">
        <div className="mb-[16px] md:mb-[24px] justify-start items-center gap-1.5 inline-flex">
          <div className="text-gray-900 text-[22px] md:text-[44px] font-bold font-['DM Sans'] leading-tight">
            {name}
          </div>
        </div>
        <div className="mb-[16px] md:mb-[24px] justify-start items-start -gap-3 inline-flex">
          {associatedCommunities.map((community) => (
            <div key={community.db_ref} className="justify-center items-center flex -ml-[7px] -md:ml-3">
              <img
                src={community.image_url}
                alt={community.name}
                title={community.name}
                className="w-[35px] h-[35px] md:w-12 md:h-12 object-cover object-center"
              />
            </div>
          ))}
        </div>
        <div className="mb-[12px] md:mb-[24px] flex-col justify-start items-start flex">
          <div className="mb-[12px] md:mb-[24px] justify-start items-center gap-1.5 flex">
            <TimerIcon className="w-6 h-6 md:w-8 md:h-8 bg-zinc-100 rounded-lg p-[5px] md:[7.5px]" />
            <div className="justify-start items-center gap-1 flex">
              <div className="text-gray-900 text-sm md:text-2xl font-medium font-['DM Sans'] leading-tight">
                {formattedFestivalDateTime}
              </div>
            </div>
          </div>

          <div className="mb-[12px] md:mb-[24px] justify-start items-center gap-1.5 flex">
            <LocationPin className="w-6 h-6 md:w-8 md:h-8 bg-zinc-100 rounded-lg p-[5px] md:[7.5px]" />
            <div
              className="cursor-pointer justify-start items-center gap-1 flex"
              onClick={handleLocationClick}
            >
              <div className="text-indigo-500 text-sm md:text-2xl font-medium font-['DM Sans'] leading-tight">
                {location}
              </div>
            </div>
          </div>

          <div className="justify-start items-center gap-1.5 flex">
            <div className="mb-[12px] md:mb-[24px] justify-start items-center gap-1.5 flex mr-4">
              <TagIcon className="w-6 h-6 md:w-8 md:h-8 bg-zinc-100 rounded-lg p-[5px] md:[7.5px]" />
              <div className="justify-start items-center gap-1 flex">
                <div className="text-gray-900 text-sm md:text-2xl font-medium font-['DM Sans'] leading-tight">
                  {+price || 'Free'}
                </div>
              </div>
            </div>

            {!!website && (
              <div className="mb-[12px] md:mb-[24px] justify-start items-center gap-1.5 flex">
                <LinkIcon className="w-6 h-6 md:w-8 md:h-8 bg-zinc-100 rounded-lg p-[5px] md:[7.5px]" />
                <a
                  className="justify-start items-center gap-1 flex"
                  href={website}
                  target="_blank"
                >
                  <div className="underline text-indigo-500 text-sm md:text-2xl font-medium font-['DM Sans'] leading-tight">
                    more info
                  </div>
                </a>
              </div>
            )}
          </div>

          <div className="mb-[12px] md:mb-[24px] justify-start items-center gap-1.5 flex">
            <PaperIcon className="w-6 h-6 md:w-8 md:h-8 bg-zinc-100 rounded-lg p-[5px] md:[7.5px]" />
            <div className="justify-start items-center gap-1 flex">
              <div className="text-gray-900 text-sm md:text-2xl font-bold font-['DM Sans'] leading-tight">
                Description
              </div>
            </div>
          </div>

          <div className="mb-[12px] md:mb-[24px] text-gray-900 text-xs md:text-base font-normal font-['DM Sans']">
            {description}
          </div>
        </div>
      </div>
    </div>
  );
};

export default FestivalInfo;
