import React, { useEffect, useRef } from 'react';
import mapboxgl from '!mapbox-gl'; // eslint-disable-line import/no-webpack-loader-syntax
import 'mapbox-gl/dist/mapbox-gl.css';
import { createPoiElement, createPoiPopup } from '../../utils/helpers/mapbox';
import PoiMarkerImage from '../../images/festival/poiMarker.png';
import NavigateIcon from '../atoms/icons/NavigateIcon';
import useAnalytics from '../../utils/hooks/useAnalytics';

mapboxgl.accessToken = process.env.GATSBY_MAP_BOX_ACCESS_TOKEN;
const ZOOM_CHANGE_THRESHOLD = 16;
function FestivalMap({ mapElementId, poiToHighlight, mapData, poiList }) {
  // Analytics
  const { fireEvent } = useAnalytics();

  const mapContainer = useRef(null);
  const map = useRef(null);

  const poiListLength = poiList?.length || 0;

  const handleRecenter = () => {
    map.current.zoomTo(18);
    map.current.flyTo({
      center: mapData.center,
      curve: 0,
    });
  };

  // render map with config and on load listener
  useEffect(() => {
    if (!mapContainer?.current && !poiListLength) return;

    map.current = new mapboxgl.Map({
      style: 'mapbox://styles/mapbox/light-v10',
      container: mapContainer.current,
      center: mapData.center,
      zoom: mapData.zoom,
      maxZoom: mapData.maxZoom,
      minZoom: mapData.minZoom,
      dragPan: true,
      scrollZoom: false,
      doubleClickZoom: true,
    });

    // inspect a cluster on click
    map.current.on('click', 'clusters', (e) => {
      const features = map.current.queryRenderedFeatures(e.point, {
        layers: ['clusters'],
      });
      const clusterId = features[0].properties.cluster_id;

      map.current
        .getSource('poiData')
        .getClusterExpansionZoom(clusterId, (err, zoom) => {
          if (err) return;

          map.current.easeTo({
            center: features[0].geometry.coordinates,
            zoom,
          });
        });

      fireEvent('shop_hop_map_aggregate_cluster_pressed', {
        event_category: 'shop_hop',
        event_label: 'map_aggregate_cluster_pressed',
      });
    });
    const markers = [];
    map.current.on('moveend', () => {
      const features = map.current.querySourceFeatures('poiData');

      const zoom = map.current.getZoom();

      if (zoom > ZOOM_CHANGE_THRESHOLD) {
        for (let i = 0; i < features.length; i++) {
          const coords = features[i].geometry.coordinates;
          const props = features[i].properties;
          let marker;
          if (props.cluster) {
            const el = document.createElement('div');
            el.classList.add('mapCluster');
            marker = new mapboxgl.Marker({ element: el }).setLngLat(coords);
          } else {
            const poiPopup = createPoiPopup(props);
            const el = createPoiElement({ id: props.id }, PoiMarkerImage);
            el.dataset.type = props.type;

            marker = new mapboxgl.Marker({ element: el })
              .setLngLat(coords)
              .setPopup(poiPopup);

            // Add click event listener to the marker
            marker.getElement().addEventListener('click', () => {
              fireEvent('shop_hop_map_business_icon_pressed', {
                event_category: 'shop_hop',
                event_label: 'map_business_icon_pressed',
                name: props.name,
                id: props.id,
              });
            });
          }

          markers.push(marker);

          marker.addTo(map.current);
        }
      } else {
        markers.forEach((marker) => marker.remove());
      }
    });
    map.current.on('load', () => {
      map.current.addControl(new mapboxgl.NavigationControl());

      map.current.addSource('poiData', {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: poiList.map((poi) => ({
            type: 'Feature',
            properties: {
              ...poi,
            },
            geometry: poi.geometry,
          })),
        },
        cluster: true,
        clusterMaxZoom: ZOOM_CHANGE_THRESHOLD + 1,
        clusterRadius: 50,
      });

      map.current.addLayer({
        id: 'clusters',
        type: 'circle',
        source: 'poiData',
        filter: ['has', 'point_count'],
        paint: {
          'circle-color': [
            'step',
            ['get', 'point_count'],
            '#6E3CFF',
            100,
            '#D965FE',
          ],
          'circle-radius': [
            'step',
            ['get', 'point_count'],
            20,
            100,
            30,
            750,
            40,
          ],
        },
      });

      // Add a layer for the cluster labels
      map.current.addLayer({
        id: 'cluster-count',
        type: 'symbol',
        source: 'poiData',
        filter: ['has', 'point_count'],
        layout: {
          'text-field': '{point_count_abbreviated}',
          'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
          'text-size': 12,
        },
        paint: {
          'text-color': '#ffffff',
        },
      });
    });

    return () => map.current.remove();
  }, [mapContainer, poiListLength]);

  // Highlight poi
  useEffect(() => {
    if (poiToHighlight) {
      map.current.flyTo({
        center: poiToHighlight.geometry.coordinates,
        curve: 0,
      });

      // Close existing popup in open state
      const popups = document.getElementsByClassName('mapboxgl-popup');

      if (popups.length) {
        popups[0].remove();
      }

      // Zoom to the building level once
      setTimeout(() => map.current.zoomTo(18), 500);

      createPoiPopup(poiToHighlight)
        .setLngLat(poiToHighlight.geometry.coordinates)
        .addTo(map.current);
    }
  }, [poiToHighlight]);

  return (
    <div className="relative h-full">
      <div className="bg-white h-[600px] w-full shadow-md">
        <div
          id={mapElementId}
          ref={mapContainer}
          style={{
            width: '100%',
            height: '100%',
            overflow: 'hidden',
          }}
        />
      </div>
      <div className="absolute bottom-8 md:bottom-12 right-2 md:right-6 lg:right-10 z-50">
        <button
          className="mb-2 md:mb-4 h-10 w-10 md:h-12 md:w-12 shadow-md bg-slate-700 text-white flex rounded-full items-center justify-center transition"
          onClick={handleRecenter}
        >
          <NavigateIcon className="text-white h-4 w-4 md:h-5 md:w-5" />
        </button>
      </div>
    </div>
  );
}

export default FestivalMap;
